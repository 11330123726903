/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "images/owncloud.jpeg"
  }, "Logo de ownCloud")), "\n", React.createElement(_components.p, null, "El proceso de instalación de ownCloud en Raspberry Pi se ha simplificado bastante, ahora la descarga e instalación es automática, podemos instalarlo en poco tiempo mediante SSH y conexión a Internet, lo vemos:"), "\n", React.createElement(_components.p, null, "Primero la instalación de apache y compañía:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">apt-get</span> <span class=\"token function\">install</span> apache2 php5 php5-json php5-gd php5-sqlite <span class=\"token function\">curl</span> libcurl3 libcurl3-dev php5-curl php5-common php-xml-parser sqlite</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Vamos a la carpeta donde se guardan los archivos del servidor web:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token builtin class-name\">cd</span> /var/www/</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Descargamos el archivo de autoinstalación:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">wget</span> https://download.owncloud.com/download/community/setup-owncloud.php</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Damos permisos a la carpeta:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">chown</span> <span class=\"token parameter variable\">-R</span> www-data:www-data /var/www</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y ya podemos entrar con el navegador en ", React.createElement(_components.em, null, "NUESTRA_IP"), "/setup-owncloud.php y con unos sencillos pasos instalar ownCloud."), "\n", React.createElement(_components.p, null, "Con ownCloud tenemos el control completo de nuestra propia nube, yo utilizo este servidor para sincronizar mis contactos entre distintos dispositivos, lo veremos en próximos posts."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
